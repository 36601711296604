import React, { useState, useEffect } from 'react';  
import { Form, Input, Button, message, Spin } from 'antd';  
import { ArrowLeftOutlined } from '@ant-design/icons';  
import { useNavigate, useLocation } from 'react-router-dom';  
import './CreatePage.css';  
import { post } from './helpers/apihelper';  
  
const Register = () => {  
  const navigate = useNavigate();  
  const location = useLocation();  
  const [errorMessage, setErrorMessage] = useState(null);  
  const [loading, setLoading] = useState(false);  
  
  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);

  const goBack = () => {  
    if (location.state?.from) {  
      navigate(location.state.from);  
    } else {  
      navigate(-1);  
    }  
  };  
  
  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleFinish = async (values) => {  
    setLoading(true);  
    try {  
      const payload = {  
        action: 'register',  
        MobileNumber: values.mobileNumber,  
        Password: values.password,  
        Name: values.name,  
        Email: values.email  
      };  
  
      const response = await post('', payload);  
      const data = response.json;  
      if (response.ok) {  
        message.success('Registration successful. Check your email/whatsapp to activate account.');
        navigate('/login');  
      } else {  
        if (data.message) {  
          setErrorMessage('Registration failed. ' + data.message);  
        } else {  
          setErrorMessage('Registration failed. Check your details.');  
        }  
      }  
    } catch (error) {  
      console.error('Registration failed with error:', error);  
      setErrorMessage('Registration failed with error');  
    } finally {  
      setLoading(false);  
    }  
  };  
  
  const handleFinishFailed = (errorInfo) => {  
    console.log('Failed:', errorInfo);  
  };  
  
  return (  
    <div className="create-page">  
      <div className="header">  
        <h3>Register a New Tenant</h3>  
        <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>  
      </div>  
      <h5>
        After registration you will get activation email and whatsapp message. Please follow the same.
      </h5>
      <Form  
        name="create-page"  
        onFinish={handleFinish}  
        onFinishFailed={handleFinishFailed}  
        autoComplete="off"  
      >  
        <Form.Item  
          name="name"  
          rules={[{ required: true, message: 'Please input your name!' }]}  
          label="Name"  
          labelCol={{ span: 24 }}  
          wrapperCol={{ span: 24 }}  
        >  
          <Input />  
        </Form.Item>  
  
        <Form.Item  
          name="email"  
          rules={[{ required: false, message: 'Please input your email!' }]}  
          label="Email (optional)"  
          labelCol={{ span: 24 }}  
          wrapperCol={{ span: 24 }}  
        >  
          <Input />  
        </Form.Item>  
  
        <Form.Item  
          name="mobileNumber"  
          rules={[{ required: true, message: 'Please input your mobile number!' }]}  
          label="Mobile Number"  
          labelCol={{ span: 24 }}  
          wrapperCol={{ span: 24 }}  
        >  
          <Input />  
        </Form.Item>  
  
        <Form.Item  
          name="password"  
          rules={[{ required: true, message: 'Please input your password!' }]}  
          label="Password"  
          labelCol={{ span: 24 }}  
          wrapperCol={{ span: 24 }}  
        >  
          <Input.Password />  
        </Form.Item>  
  
        <Form.Item>  
          <Button type="primary" htmlType="submit" disabled={loading}>  
            {loading ? <Spin /> : 'Register'}  
          </Button> 
          <Button style={{ marginLeft: '8px' }} onClick={handleLoginClick}>
            Login
          </Button> 
        </Form.Item>  
      </Form>  
    </div>  
  );  
};  
  
export default Register;  
