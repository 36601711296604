import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext';
import './CreatePage.css';
import { post } from './helpers/apihelper';

const CreateUser = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);
  
  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);

  const goBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const payload = {
        action: 'register',
        MobileNumber: values.mobileNumber,
        Password: values.password,
        Name: values.name,
        Email: values.email,
        TenantGuid: user.tenantguid
      };

      const response = await post('', payload);
      const data = response.json;
      if (response.ok) {
        message.success('User creation successful.');
        navigate('/users');
      } else {
        if (data.message) {
          setErrorMessage('User creation failed. ' + data.message);
        } else {
          setErrorMessage('User creation failed. Check your details.');
        }
      }
    } catch (error) {
      console.error('User creation failed with error:', error);
      setErrorMessage('User creation failed with error');
    } finally {
      setLoading(false);
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="create-page">
      <div className="header">
        <h3>Create User</h3>
        <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
      </div>
      <Form
        name="create-page"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Please input name!' }]}
          label="Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[{ required: false, message: 'Please input email!' }]}
          label="Email (optional)"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="mobileNumber"
          rules={[{ required: true, message: 'Please input mobile number!' }]}
          label="Mobile Number (Login Id)"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input password!' }]}
          label="Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? <Spin /> : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateUser;  
