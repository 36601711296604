import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Spin, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import './CreatePage.css';
import { post } from './helpers/apihelper';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState('mobile');

    useEffect(() => {
        if (errorMessage) {
            message.error(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage]);

    const goBack = () => {
        if (location.state?.from) {
            navigate(location.state.from);
        } else {
            navigate(-1);
        }
    };

    const handleFinish = async (values) => {
        setLoading(true);
        try {
            const payload = {  
                action: 'forgotpassword',  
                ...(method === 'mobile' && { MobileNumber: values.loginid }),  
                ...(method === 'email' && { Email: values.loginid }),  
            }; 

            const response = await post('', payload);
            if (response.ok) {
                message.success('Temp password has been sent to your ' + (method === 'mobile' ? 'whatsapp number' : 'email') + '.');
                navigate('/login');
            } else {
                if (response.message) {
                    setErrorMessage('Password reset failed. ' + response.message);
                } else {
                    setErrorMessage('Password reset failed. Check your ' + (method === 'mobile' ? 'mobile number' : 'email') + '.');
                }
            }
        } catch (error) {
            console.error('Password reset failed with error:', error);
            setErrorMessage('Password reset failed with error');
        } finally {
            setLoading(false);
        }
    };

    const handleFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="create-page">
            <div className="header">
                <h3>Forgot Password</h3>
                <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
            </div>
            <Form
                name="create-page"
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                autoComplete="off"
            >
                <Form.Item>
                    <Radio.Group onChange={e => setMethod(e.target.value)} value={method}>
                        <Radio value={'mobile'}>Login Id (Mobile Number)</Radio>
                        <Radio value={'email'}>Email Id</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="loginid"
                    rules={[
                        { required: true, message: 'Please input your ' + (method === 'mobile' ? 'mobile number' : 'email') + '!' },
                        (method === 'email' && {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        })
                    ]}
                    label={method === 'mobile' ? "Mobile Number" : "Email"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input type={method === 'email' ? 'email' : 'text'} />
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                        {loading ? <Spin /> : 'Reset Password'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ForgotPassword;  
