import { post } from './helpers/apihelper';  
  
export const handlePayment = (navigate, user, productid, plan = 1, paymentmode, price, amount, productname, username, mobilenumber, emailid = null) => {    
    const options = {    
        key: 'rzp_live_UWQMZBosEuuowm',    
        amount: amount * 100, // amount in the smallest currency unit        
        currency: 'INR',    
        name: 'Gist Computer Technology',    
        description: productname,    
        handler: async function (response) {   
            console.log(response);   
            if (response.status_code === 200) {    
                const payload = {    
                    action: 'createsubscription',    
                    TenantID: user.tenantid,    
                    ProductID: productid,  
                    ProductName:  productname,  
                    Quantity: plan,    
                    Price: price,    
                    Amount: amount,    
                    PaymentRefNumber: response.razorpay_payment_id,    
                    PaymentMode: paymentmode    
                };    
  
                let rsp;  
                for(let i = 0; i < 3; i++) {  
                    rsp = await post('', payload, user.access_token);    
                    if (rsp.ok) {  
                        alert('Payment successful.');    
                        navigate('/subscriptions');    
                        break;  
                    } else if(i === 2) { // if it's the last attempt  
                        if (rsp.message) {    
                            alert('Payment failed. But do not worry we will refund. ' + rsp.message);    
                        } else {    
                            alert('Payment failed. Contact to administrator. But do not worry we will refund.');    
                        }    
                    }  
                }    
            }    
        },    
        prefill: {    
            name: username,    
            email: emailid ? emailid : 'gisterp@gmail.com',    
            contact: mobilenumber    
        },    
        notes: {    
            address: 'Registered office-  Noida Sec -1, U.P. 201306'    
        },    
        theme: {    
            color: '#F37254'    
        }    
    };    
  
    const rzp1 = new window.Razorpay(options);    
    rzp1.open();    
}    
