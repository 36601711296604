const API_URL = 'https://tenant.gisterp.com';

async function get(endpoint, token) {
    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    try {
        const response = await fetch(`${API_URL}${endpoint}`, { headers });
        var json = await response.json();
        return { ok: response.ok, status: response.status, json: json };
    } catch (error) {
        console.error('GET request failed:', error);
        throw error;
    }
}

async function post(endpoint, data, token) {
    const headers = {
        'Content-Type': 'application/json',
        ...token ? { 'Authorization': `Bearer ${token}` } : {}
    };

    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });

        var json = await response.json();
        return { ok: response.ok, status: response.status, json: json };
    } catch (error) {
        console.error('POST request failed:', error);
        throw error;
    }
}

export { get, post };  
