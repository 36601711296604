import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Select, Upload, message, Collapse, Typography } from 'antd';
import { UploadOutlined, ArrowLeftOutlined, InfoCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import './CreatePage.css';
import AuthContext from './AuthContext';
import { post } from './helpers/apihelper';
import { handlePayment } from './PaymentProcessor';

const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography;

const Subscribe = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOffline, setIsOffline] = useState(false);
  const [plan, setPlan] = useState(1);
  const [amount, setAmount] = useState(0);
  const [basicAmount, setBasicAmount] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await post('', {
          action: 'listproducts',
          TenantGuid: user.tenantguid
        }, user.access_token);

        console.log(response);
        if (response.ok && response.json) {
          setProducts(response.json);
        };
      } catch (error) {
        console.error(error);
        message.error('Failed to fetch products');
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const total = selectedProduct.Price * plan;
      const tax = total * 0.18;
      setBasicAmount(total);
      setAmount(Math.round((total + tax - discount), 0));
    }
  }, [selectedProduct, plan, discount]);

  useEffect(() => {
    let discount = 0;
    if (selectedProduct) {
      if (plan === '2') {
        discount = 0.05;
      } else if (plan === '3') {
        discount = 0.10;
      } else if (plan === '5') {
        discount = 0.20;
      }
      setDiscount(discount * selectedProduct.Price * plan);
    }
  }, [selectedProduct, plan]);

  const goBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  const handlePaymentModeChange = (value) => {
    if (value === '2') {
      setIsOffline(true);
    } else {
      setIsOffline(false);
    }
  };

  const handleProductChange = (value) => {
    const product = products.find(product => product.ProductID === value);
    setSelectedProduct(product);
  };

  const handlePlanChange = (value) => {
    setPlan(value);
  };

  const handleFinish = async (values) => {
    console.log('Form values:', values);
    if (values.paymentmode === '1') {
      handlePayment(navigate, user, selectedProduct.ProductID, plan, values.paymentmode, selectedProduct.Price, amount, selectedProduct.Name, "Tenant " + user.tenantid.toString(), user.mobilenumber, user.email);
    } else if (values.paymentmode === '2') {
      const payload = {
        action: 'createsubscription',
        TenantID: user.tenantid,
        ProductID: selectedProduct.ProductID,
        ProductName: selectedProduct.Name,
        Quantity: plan,
        Price: selectedProduct.Price,
        Amount: amount,
        PaymentRefNumber: values.paymentrefnumber,
        PaymentMode: values.paymentmode
      };

      console.log(payload);

      let rsp;
      rsp = await post('', payload, user.access_token);
      if (rsp.ok) {
        alert('Order places successfully. Subscription will be acivated after verification.');
        navigate('/subscriptions');
      }else{
        alert('Not able to save. Try again.');
      }
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="create-page">
      <div className="header">
        <h3>Buy a License</h3>
        <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
      </div>
      <Form
        name="create-page"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="product"
          rules={[{ required: true, message: 'Please select a product!' }]}
          label="Product"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select placeholder="Select Product" onChange={handleProductChange}>
            {products.map(product => (
              <Option key={product.ProductID} value={product.ProductID}>{product.Name}</Option>
            ))}
          </Select>
        </Form.Item>

        {selectedProduct && (
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Product Details" key="1">
              <p><InfoCircleOutlined /> Description: {selectedProduct.Description}</p>
              <p><DollarCircleOutlined /> Price: {selectedProduct.Price} INR</p>
            </Panel>
          </Collapse>
        )}

        <Form.Item
          name="quantity"
          rules={[{ required: true, message: 'Please select a plan!' }]}
          label="Plan"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select placeholder="Select Plan" onChange={handlePlanChange}>
            <Option value="1">1 Year</Option>
            <Option value="2">2 Years</Option>
            <Option value="3">3 Years</Option>
            <Option value="5">5 Years</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="paymentmode"
          rules={[{ required: true, message: 'Please select payment mode!' }]}
          label="Payment Mode"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select placeholder="Select Payment Mode" onChange={handlePaymentModeChange}>
            <Option value="1">Online</Option>
            {/* <Option value="2">Offline</Option> */}
          </Select>
        </Form.Item>

        {isOffline && (
          <Form.Item
            name="paymentrefnumber"
            rules={[{ required: true, message: 'Please input payment reference number!' }]}
            label="Payment Reference Number"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
        )}

        {selectedProduct && (
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Amount Summary (INR)" key="1">
              <p><Text>Basic Amount: {basicAmount ? basicAmount.toFixed(2) : "0.00"}</Text></p>
              <p><Text>Discount: {discount ? discount.toFixed(2) : "0.00"}</Text></p>
              <p><Text strong>Total Amount (with 18% tax): {amount ? Math.round(amount, 0).toFixed(2) : "0.00"}</Text></p>
            </Panel>
          </Collapse>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Place Order
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Subscribe;  
