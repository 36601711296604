import React, { useState, useContext, useEffect } from 'react';
import { Table, Space, Button, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './ListPage.css';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import { Link } from 'react-router-dom';
import { post } from './helpers/apihelper';

const UsersList = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    console.log(user.tenantguid);
    const fetchUsers = async () => {
      const response = await post('', {
        action: 'listusers',
        TenantGuid: user.tenantguid
      }, user.access_token);

      if (response.ok && response.json) {
        const users = response.json.map(user => ({
          ...user,
          UserType: 'User'
        }));

        setData(users);
      }
    };

    fetchUsers();
  }, [user]);

  const columns = [
    {
      title: 'Mobile Number',
      dataIndex: 'MobileNumber',
      key: 'MobileNumber',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'User Type',
      dataIndex: 'UserType',
      key: 'UserType',
      render: type => (
        <Tag color={type === 'User' ? 'geekblue' : type === 'Order Received' ? 'green' : 'volcano'}>
          {type}
        </Tag>)
    },
    {
      title: 'State',
      dataIndex: 'IsActive',
      key: 'IsActive',
      render: state => (
        <Tag color={state === 1 ? 'green' : 'volcano'}>
          {state && state === 1 ? "Active" : "InActive"}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record)}></Button>
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    console.log(record);
    navigate('/users/update', { state: { user: record } });
  };

  const handleDelete = (record) => {
    console.log('Delete:', record);
    // Add your delete logic here    
  };

  return (
    <div className="list-page">
      <div className="header">
        <h3>Existing Users List</h3>
      </div>
      <Link to="/users/create">
        <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: '16px', marginTop: '16px' }}>
          Create User
        </Button>
      </Link>
      <div className="table" style={{ overflowX: 'auto' }}>
        <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
      </div>
    </div>
  );
};

export default UsersList;  
