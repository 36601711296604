import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import AuthContext from './AuthContext';
import './CreatePage.css';
import { post } from './helpers/apihelper';

const ChangePassword = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);
  
  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);

  const goBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  const handleFinish = async (values) => {
    if (values.newpassword !== values.confirmpassword) {
      setPasswordError('New password and confirm password do not match');
      return;
    }

    if (values.newpassword === oldPassword) {
      setPasswordError('New password should not be the same as old password');
      return;
    }

    setLoading(true);
    try {
      const payload = {
        action: 'updatepassword',
        TenantID: user.tenantId,
        MobileNumber: user.loginid,
        OldPassword: values.oldpassword,
        NewPassword: values.newpassword
      };

      const response = await post('', payload, user.access_token);
      if (response.ok) {
        message.success('Password updated successfully.');
        navigate('/');
      } else {
        if (response.message) {
          setErrorMessage('Password update failed. ' + response.message);
        } else {
          setErrorMessage('Password update failed. Check your details.');
        }
      }
    } catch (error) {
      console.error('Password update failed with error:', error);
      setErrorMessage('Password update failed with error');
    } finally {
      setLoading(false);
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancelClick = () => {
    goBack();
  };

  return (
    <div className="create-page">
      <div className="header">
        <h3>Change Password</h3>
        <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
      </div>
      <Form
        name="create-page"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="loginid"
          label="Login Id (Mobile Number)"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={user.loginid ? user.loginid : "(unauhorised)"}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="oldpassword"
          rules={[{ required: true, message: 'Please input old password!' }]}
          label="Old Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password onChange={e => setOldPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="newpassword"
          rules={[{ required: true, message: 'Please input new password!', min: 3 }]}
          label="New Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmpassword"
          rules={[{ required: true, message: 'Please input confirm password!', min: 3 }]}
          label="Confirm Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? <Spin /> : 'Update Password'}
          </Button>
          <Button style={{ marginLeft: '8px' }} onClick={handleCancelClick}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;  
