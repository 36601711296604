import React, { useState } from 'react';  
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from 'react-dom';  
import './index.css';  
import App from './App';  
import reportWebVitals from './reportWebVitals';  
import AuthContext from './AuthContext';  
  
const Root = () => {    
    const [user, setUser] = useState(null);    
    
    return (    
        <AuthContext.Provider value={{ user, setUser }}>    
            <React.StrictMode>    
                <Router>  
                    <App />    
                </Router>  
            </React.StrictMode>    
        </AuthContext.Provider>    
    );    
}  
  
const root = ReactDOM.createRoot(document.getElementById('root'));  
root.render(<Root />);  
  
reportWebVitals();  
