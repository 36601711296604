import React, { useContext } from 'react';  
import { Button } from 'antd';  
import { UserOutlined, UploadOutlined } from '@ant-design/icons';  
import { useNavigate } from 'react-router-dom';  
import AuthContext from './AuthContext';  
  
const ChangePwdButton = () => {  
    const { user} = useContext(AuthContext);  
    const navigate = useNavigate();  
  
    const handleChangePwdButtonClick = () => {  
        if (user) {  
			navigate('/changepassword');  
        } else {  
            // Navigate to the Login page  
            navigate('/register');  
        }  
    };  
  
    return (  
        <Button type="primary" icon={user ? <UploadOutlined /> : <UserOutlined />} style={{ marginLeft: '15px' }} onClick={handleChangePwdButtonClick}>  
            {user ? 'Change Password' : 'Register'}  
        </Button>  
    );  
};  
  
export default ChangePwdButton;  
