import React, { useState, useContext, useEffect } from 'react';
import { Layout, Menu, Card, Col, Row } from 'antd';
import { HomeOutlined, UserOutlined, QuestionCircleOutlined, UserAddOutlined, MailOutlined, ClockCircleOutlined, SettingOutlined, FileTextOutlined, LoginOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Route, Routes, Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from './AuthContext';
import LoginButton from './LoginButton';
import ChangePwdButton from './ChangePwdButton';
import SubscriptionsList from './SubscriptionsList';
import Subscribe from './Subscribe';
import TemplatesList from './TemplatesList';
import CreateTemplate from './CreateTemplate';
import UsersList from './UsersList';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import RegisterUser from './RegisterUser';
import Login from './Login';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import './App.css';
import { post } from './helpers/apihelper';

const { Header, Sider, Content, Footer } = Layout;
const logo = process.env.PUBLIC_URL + '/logo.gif';

const App = () => {
    const { user } = useContext(AuthContext);
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const openUrl = (url) => {
        window.open(url, "_blank");
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tenantguid = query.get('tenantguid');
        if (tenantguid) {
            const [TenantId, TenantGuid] = tenantguid.split('_');

            const updateTenantGuid = async () => {
                const payload = {
                    action: 'updatetenantguid',
                    TenantId: TenantId,
                    TenantGuid: TenantGuid
                };

                const response = await post('', payload);
                const data = response.json;
                if (response.ok) {
                    alert("Account activation successful.");
                } else {
                    alert(response.message);
                }
            };

            updateTenantGuid();
        }
    }, [location]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Call the function directly to set the initial state  
        handleResize();

        // Clean up event listener on unmount  
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider trigger={null} collapsible collapsed={collapsed} className="custom-sider">
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" onClick={() => navigate("/")} />
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: toggle,
                    })}
                </div>
                <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
                    <Menu.Item key="/" icon={<HomeOutlined />}>
                        <Link to="/">Home</Link>
                    </Menu.Item>
                    {user && (
                        <>
                            <Menu.Item key="/subscriptions" icon={<ClockCircleOutlined />}>
                                <Link to="/subscriptions">License</Link>
                            </Menu.Item>
                            <Menu.Item key="/users" icon={<SettingOutlined />}>
                                <Link to="/users">Users</Link>
                            </Menu.Item>
                            <Menu.Item key="/templates" icon={<MailOutlined />}>
                                <Link to="/templates">Templates</Link>
                            </Menu.Item>
                        </>
                    )}
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="header">
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: toggle,
                    })}
                    <div className="user-info">
                        <span>{user ? "Login Id: " + user.loginid : 'Welcome to GistERP'}</span>
                        <ChangePwdButton />
                        {user ? <LoginButton /> : null}
                    </div>
                </Header>

                <Content className="content">
                    {location.pathname === '/home' || location.pathname === '/' && (
                        <div className="site-card-wrapper">
                            {user && (
                                <Row gutter={16} className="tiles-row">
                                    <Col span={8}>
                                        <Card title="Subscriptions" bordered={false} onClick={() => navigate('/subscriptions')} className="clickable-card">
                                            <ClockCircleOutlined style={{ fontSize: '48px', color: '#08c' }} />
                                            <p>Manage license</p>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Users" bordered={false} onClick={() => navigate('/users')} className="clickable-card">
                                            <UserOutlined style={{ fontSize: '48px', color: '#08c' }} />
                                            <p>Manage users</p>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Templates" bordered={false} onClick={() => navigate('/templates')} className="clickable-card">
                                            <FileTextOutlined style={{ fontSize: '48px', color: '#08c' }} />
                                            <p>Message templates</p>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            {!user && (
                                <Row gutter={16} className="tiles-row">
                                    <Col span={8}>
                                        <Card title="Login" bordered={false} onClick={() => navigate('/login')} className="clickable-card">
                                            <LoginOutlined style={{ fontSize: '48px', color: '#08c' }} />
                                            <p>Login to account</p>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Register" bordered={false} onClick={() => navigate('/register')} className="clickable-card">
                                            <UserAddOutlined style={{ fontSize: '48px', color: '#08c' }} />
                                            <p>New account</p>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Contact Us" bordered={false} className="clickable-card"
                                            onClick={() => openUrl('https://help.gisterp.com/')}>
                                            <QuestionCircleOutlined style={{ fontSize: '48px', color: '#08c' }}
                                            />
                                            <p>Need support?</p>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )}

                    <div className="inner-content">
                        <Routes>
                            <Route path="/subscriptions" element={<SubscriptionsList />} />
                            <Route path="/templates" element={<TemplatesList />} />
                            <Route path="/users" element={<UsersList />} />
                            <Route path="/templates/create" element={<CreateTemplate />} />
                            <Route path="/subscriptions/create" element={<Subscribe />} />
                            <Route path="/users/create" element={<CreateUser />} />
                            <Route path="/users/update" element={<UpdateUser />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<RegisterUser />} />
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="/forgotpassword" element={<ForgotPassword />} />
                        </Routes>
                    </div>
                </Content>
                <Footer className="footer">Gist Computer Technology ©2024</Footer>
            </Layout>
        </Layout>
    );
}

export default App;  
