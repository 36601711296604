import React, { useContext } from 'react';  
import { Button } from 'antd';  
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';  
import { useNavigate } from 'react-router-dom';  
import AuthContext from './AuthContext';  
  
const LoginButton = () => {  
    const { user, setUser } = useContext(AuthContext);  
    const navigate = useNavigate();  
  
    const handleLoginButtonClick = () => {  
        if (user) {  
            // Logout the user  
            setUser(null);  
			navigate('/');  
        } else {  
            // Navigate to the Login page  
            navigate('/login');  
        }  
    };  
  
    return (
        <Button type="primary" icon={user ? <LogoutOutlined /> : <LoginOutlined />} style={{ marginLeft: '15px' }} onClick={handleLoginButtonClick}>  
            {user ? 'Logout' : 'Login'}  
        </Button>  
    );  
};  
  
export default LoginButton;  
