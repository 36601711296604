import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import AuthContext from './AuthContext';
import './CreatePage.css';
import { post } from './helpers/apihelper';

const Login = () => {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);

  const goBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const payload = {
        action: 'login',
        MobileNumber: values.loginid,
        Password: values.password
      };

      const response = await post('', payload);
      const data = response.json;
      if (response.ok) {
        if (data['tenantguid'] && data['access_token']) {
          setUser({
            loginid: values.loginid,
            tenantguid: data['tenantguid'],
            tenantid: data['tenantid'],
            access_token: data['access_token']
          });
          navigate('/');
        } else if (!data['tenantguid'] || (data['IsActive'] === 0 || data['IsActive'] === false) || data['tenantguid'] === "") {
          setErrorMessage('Your account is not active. Send "hi" to Whatsapp number 9818334745 to get activated.');
        }
        else {
          setErrorMessage('Invalid tenant. Contact with administrator.');
        }
      } else {
        if (data.message) {
          setErrorMessage('Login failed. ' + data.message);
        } else {
          setErrorMessage('Login failed. Check login id and password.');
        }
      }
    } catch (error) {
      console.error('Login failed with error:', error);
      setErrorMessage('Login failed with error');
    } finally {
      setLoading(false);
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div className="create-page">
      <div className="header">
        <h3>Login</h3>
        <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
      </div>
      <h5>
        Facing issue to activate an account? Just send "hi" on Whatsapp number 9818334745.
      </h5>
      <Form
        name="create-page"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="loginid"
          rules={[{ required: true, message: 'Please input login id (mobile number)!' }]}
          label="Login Id (Mobile Number)"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input password!' }]}
          label="Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? <Spin /> : 'Login'}
          </Button>
          <Button style={{ marginLeft: '8px' }} onClick={handleRegisterClick}>
            Register
          </Button>
          <Button type="link" onClick={() => navigate('/forgotpassword')}>
            Forgot Password?
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;   
