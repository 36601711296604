import React, { useState, useContext, useEffect } from 'react';
import { Table, Space, Button, Tag } from 'antd';
import { CheckCircleOutlined, MinusCircleOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './ListPage.css';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import { Link } from 'react-router-dom';
import { post } from './helpers/apihelper';

const SubscriptionsList = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    console.log(user.tenantguid);
    const fetchSubscriptions = async () => {
      const response = await post('', {
        action: 'listsubscriptions',
        TenantGuid: user.tenantguid
      }, user.access_token);

      if (response.ok && response.json) {
        const subscriptions = response.json.map(subscription => ({
          ...subscription,
          SubscriptionType: 'Subscription'
        }));

        setData(subscriptions);
      }
    };

    fetchSubscriptions();
  }, [user]);

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
    },
    {
      title: 'Payment Mode',
      dataIndex: 'PaymentMode',
      key: 'PaymentMode',
      render: type => (
        <Tag color={type === 1 ? 'green' : type === 2 ? 'geekblue' : 'volcano'}>
          {type && type === 1 ? "Online" : "Offline"}
        </Tag>)
    },
    {
      title: 'License',
      dataIndex: 'LicenseID',
      key: 'LicenseID',
      render: licenseID => {
        if (licenseID) {
          return <Tag icon={<CheckCircleOutlined />} color="success" onClick={() => alert('License already issued. Contact support to get help.')} />
        } else {
          return <Tag icon={<MinusCircleOutlined />} color="warning" />
        }
      },
    },
    {
      title: 'Cancelled',
      dataIndex: 'IsCancelled',
      key: 'IsCancelled',
      render: state => (
        <Tag color={state === 1 ? 'volcano' : 'green'}>
          {state && state === 1 ? "Yes" : "No"}
        </Tag>
      ),
    }
  ];

  return (
    <div className="list-page">
      <div className="header">
        <h3>Existing License Subscriptions</h3>
      </div>
      <Link to="/subscriptions/create">
        <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: '16px', marginTop: '16px' }}>
          Place Order
        </Button>
      </Link>
      <div className="table" style={{ overflowX: 'auto' }}>
        <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
      </div>
    </div>
  );
};

export default SubscriptionsList;  
