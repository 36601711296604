import React from 'react';    
import { Form, Input, Button, Select, Upload, message} from 'antd';    
import { UploadOutlined,ArrowLeftOutlined } from '@ant-design/icons';    
import { useNavigate, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';    
import 'react-quill/dist/quill.snow.css';    
import './CreatePage.css';    
    
const { Option } = Select;    
const { TextArea } = Input;    
    
const CreateTemplate = () => {   
  const navigate = useNavigate();  
  const location = useLocation();  
  
  const goBack = () => {  
    if(location.state?.from) {  
      navigate(location.state.from);  
    } else {  
      navigate(-1);  
    }  
  };  

  const handleFinish = (values) => {    
    console.log('Form values:', values);    
  };    
    
  const handleFinishFailed = (errorInfo) => {    
    console.log('Failed:', errorInfo);    
  };    
    
  const handleBeforeUpload = (file) => {    
    const isLt2M = file.size / 1024 / 1024 < 2;    
    if (!isLt2M) {    
      message.error('File must smaller than 2MB!');    
    }    
    return isLt2M;    
  };    
  
  return (    
    <div className="create-page"> 
	  <div className="header">  
		<h3>Create Message Template</h3>    
        <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
      </div>  
     <Form    
        name="create-page"    
        onFinish={handleFinish}    
        onFinishFailed={handleFinishFailed}    
        autoComplete="off"    
      >     
	   <Form.Item    
          name="type"    
          rules={[{ required: true, message: 'Please select template type!' }]}    
          label="Template Type"  
		  labelCol={{ span: 24 }}  
			wrapperCol={{ span: 24 }}  
        >    
          <Select placeholder="Select Template Type">    
            <Option value="NewInvoice">New Invoice</Option>    
            <Option value="NewOrder">New Order</Option>    
            <Option value="PendingBalanceReminder">Pending Balance</Option>    
          </Select>    
        </Form.Item>   
		
       <Form.Item    
			name="name"    
			rules={[{ required: true, message: 'Please input template name!' }]}    
			label="Template Name"  
			labelCol={{ span: 24 }}  
			wrapperCol={{ span: 24 }}  
		>    
			<Input />    
		</Form.Item>    
 
  
        <Form.Item    
          name="subject"    
          rules={[{ required: true, message: 'Please input subject!' }]}    
          label="Subject"  
		  labelCol={{ span: 24 }}  
			wrapperCol={{ span: 24 }}  
        >    
          <Input />    
        </Form.Item>    
  
        <Form.Item    
          name="body"    
          rules={[{ required: true, message: 'Please input body!' }]}    
          label="Body" 
labelCol={{ span: 24 }}  
			wrapperCol={{ span: 24 }}  		  
        >    
          <ReactQuill theme="snow" className="quill" />    
        </Form.Item>    
  
        <Form.Item    
          name="attachment"    
          rules={[{ required: false, message: 'Please upload attachment!' }]}    
          label=" "  
		  labelCol={{ span: 24 }}  
			wrapperCol={{ span: 24 }}  
        >    
          <Upload     
            name="file"    
            beforeUpload={handleBeforeUpload}    
            maxCount={1}    
          >    
            <Button icon={<UploadOutlined />}>Attachment</Button>    
          </Upload>    
        </Form.Item>    
  
        <Form.Item>    
          <Button type="primary" htmlType="submit">    
            Save    
          </Button>    
          <Button style={{ marginLeft: '8px' }}>    
            Cancel    
          </Button>    
        </Form.Item>    
      </Form>    
    </div>    
  );    
};    
    
export default CreateTemplate;    
