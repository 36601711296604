import React from 'react';
import { Table, Space, Button, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './ListPage.css';
import { Link } from 'react-router-dom';

const TemplatesList = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: type => (
        <Tag color={type === 'New Invoice' ? 'green' : type === 'New Order' ? 'geekblue' : 'volcano'}>
          {type}
        </Tag>)
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: state => (
        <Tag color={state === 'Active' ? 'green' : 'volcano'}>
          {state}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handlePreview(record)}></Button>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record)}></Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 'User Registration Template',
      subject: 'New User Registration - Welcome to our platform',
      type: 'New Invoice',
      state: 'Active',
    },
    {
      key: '2',
      name: 'Order Received Template',
      subject: 'Your order has been received',
      type: 'New Order',
      state: 'Active',
    },
    {
      key: '3',
      name: 'Daily Balance Update Template',
      subject: 'Your daily balance update',
      type: 'Pending Balance',
      state: 'InActive',
    },
  ];

  const handlePreview = (record) => {
    console.log('Preview:', record);
    // Add your preview logic here  
  };

  const handleEdit = (record) => {
    console.log('Edit:', record);
    // Add your edit logic here  
  };

  const handleDelete = (record) => {
    console.log('Delete:', record);
    // Add your delete logic here  
  };

  return (
    <div className="list-page">
      <div className="header">
        <h3>Existing Message Template List</h3>
      </div>
      <Link to="/templates/create">
        <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: '16px', marginTop: '16px' }}>
          Create Template
        </Button>
      </Link>
      <div className="table" style={{ overflowX: 'auto' }}>
        <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
      </div>
    </div>
  );
};

export default TemplatesList;  
